<template>
  <div class="main_container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/addBplan'">
          Business plan >
        </router-link>
        <span>Résultat</span>
      </div>
    </div>
    
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Business plan
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <a class="btn btn-blue"
           data-toggle="modal"
           ref="modal_button"
           data-target="#exampleModal"
           @click="effacerForm()">
          Imprimer
        </a>
      </div>
      
    </div>
    <div class="row my-2">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <div class="row mt-2">
      <div style="width:98%;margin-left: 8px;" 
           class="stats_card row my-2">
        <div class="col-md-4">
          <div class="s_card card_dark">
            <div class="s_cont">
              <h4 v-if="resultat.VAN">{{ resultat.VAN.toLocaleString() }} FCFA</h4>
              <p>V.A.N.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="s_card card_dark">
            <div class="s_cont">
              <h4 v-if="resultat.TRI || resultat.TRI >= 0">{{ resultat.TRI.toLocaleString() }} %</h4>
              <p>T.R.I.</p>
            </div>
          </div>
        </div>
      </div>
      <div style="width:98%;margin-left: 8px;" 
           class="stats_card row my-2">
        
      </div>
    </div>
    <div class="row pt-3 mx-auto">
      <table class="table table-striped"
             v-if="loaded">
        
        <thead><!--Cashflow-->
          <tr>
            <th class="th-blue text-uppercase">Période</th>
            <th class="th-blue text-right"
                v-for="(periode, periodekey) in resultat.periode[0]"
                :key="'periodeflow'+periodekey">{{ periode.toLocaleString() }}</th>
          </tr>
          <tr>
            <th class="tht-dark-blue text-uppercase">Cashflow</th>
            <th class="tht-dark-blue text-right"
                v-for="(cash, cashkey) in resultat.cash_flow[0]"
                :key="'cashflow'+cashkey">{{ cash.toLocaleString() }}</th>
          </tr>
        </thead>
        <thead><!--Cashflow actualisé-->
          <tr>
            <th class="tht-dark-blue text-uppercase">Cashflow actualisé</th>
            <th class="tht-dark-blue text-right"
                v-for="(cash_flow_actualise, cashkey) in resultat.cash_flow_actualise[0]"
                :key="'cashflow'+cashkey">{{ cash_flow_actualise.toLocaleString() }}</th>
          </tr>
        </thead>
        <!-- TABLEAU DES CHARGES -->
        <thead><!--Header des charges-->
          <tr @click="showContent('c')">
            <th class="tht-dark-blue text-uppercase"> Charges</th>
            <th class="tht-dark-blue text-right"
                v-for="(total, totkey) in total.charge"
                :key="'Chargemontant'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>

        <thead v-if="contenu.c">
          <tr @click="showContent('ce')">
            <th class="tht-blue text-uppercase"> Charges d'exploitation </th>
            <th class="tht-blue text-right"
                v-for="(total, totkey) in total.c_exploitation"
                :key="'cextot'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>
        
        <tbody v-if="contenu.c && contenu.ce">
          <tr scope="row"
              v-for="(exploitation,cexpkey) in resultat.charges[0].charges_exploitation"
              :key="'ce_'+cexpkey">
            <td style="width: 40%;">{{ exploitation.libelle }}</td>
            <template v-if="exploitation.montant[0].length > 0">
              <td class="text-right"
                  v-for="(montant, mtkey) in exploitation.montant[0]"
                  :key="'cexmontant'+mtkey">{{ montant.toLocaleString() }}</td>
            </template>
            <template v-else>
              <td class="text-center"
                  :colspan="resultat.periode[0].length">Aucune données...</td>
            </template>
          </tr>
        </tbody>

        <thead v-if="contenu.c">
          <tr @click="showContent('ci')">
            <th class="tht-blue text-uppercase"> Charges d'investissement </th>
            <th class="tht-blue text-right"
                v-for="(total, totkey) in total.c_investissement"
                :key="'cinvtotal'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>
        
        <tbody v-if="contenu.c && contenu.ci">
          <tr scope="row"
              v-for="(investissement,cinvkey) in resultat.charges[0].charges_investissement"
              :key="'ci_'+cinvkey">
            <td>{{ investissement.libelle }}</td>
            <template v-if="investissement.montant[0].length > 0">
              <td class="text-right"
                  v-for="(montant, mtkey) in investissement.montant[0]"
                  :key="'cinmontant'+mtkey">{{ montant.toLocaleString() }}</td>
            </template>
            <template v-else>
              <td class="text-center"
                  :colspan="resultat.periode[0].length">Aucune données...</td>
            </template>
          </tr>
        </tbody>
        
        <thead v-if="contenu.c">
          <tr @click="showContent('ca')">
            <th class="tht-blue text-uppercase"> Autres Charges </th>
            <th class="tht-blue text-right"
                v-for="(total, totkey) in total.c_autre"
                :key="'cautotal'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>
        
        <tbody v-if="contenu.c && contenu.ca">
          <tr scope="row"
              v-for="(autre,caukey) in resultat.charges[0].autres_charges"
              :key="'ca_'+caukey">
            <td >{{ autre.libelle }}</td>
            <template v-if="autre.montant[0].length > 0">
              <td class="text-right"
                  v-for="(montant, mtkey) in autre.montant[0]"
                  :key="'caumontant'+mtkey">{{ montant.toLocaleString() }}</td>
            </template>
            <template v-else>
              <td class="text-center"
                  :colspan="resultat.periode[0].length">Aucune données...</td>
            </template>
          </tr>
        </tbody>
        <!-- TABLEAU DES RESSOURCES -->
        <thead><!--Header des ressources-->
          <tr @click="showContent('r')">
            <th class="tht-dark-blue text-uppercase"> Ressources</th>
            <th class="tht-dark-blue text-right"
                v-for="(total, totkey) in total.ressource"
                :key="'resmontant'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>

        <thead v-if="contenu.r">
          <tr @click="showContent('re')">
            <th class="tht-blue text-uppercase"> Ressources d'exploitation </th>
            <th class="tht-blue text-right"
                v-for="(total, totkey) in total.r_exploitation"
                :key="'exmontant'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>
        
        <tbody v-if="contenu.r && contenu.re">
          <tr scope="row"
              v-for="(exploitation,rexpkey) in resultat.resoucres[0].ressources_exploitation"
              :key="'re_'+rexpkey"
              @click="showContent('re')">
            <td >{{ exploitation.libelle }}</td>
            <template v-if="exploitation.montant[0].length > 0">
              <td class="text-right"
                  v-for="(montant, mtkey) in exploitation.montant[0]"
                  :key="'aumontant'+mtkey">{{ montant.toLocaleString() }}</td>
            </template>
            <template v-else>
              <td class="text-center"
                  :colspan="resultat.periode[0].length">Aucune données...</td>
            </template>
          </tr>
        </tbody>

        <thead v-if="contenu.r">
          <tr @click="showContent('ri')">
            <th class="tht-blue text-uppercase"> Ressources d'investissement </th>
            <th class="text-right tht-blue"
                v-for="(total, totkey) in total.r_investissement"
                :key="'invtotal'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>
        
        <tbody v-if="contenu.r && contenu.ri">
          <tr scope="row"
              v-for="(investissement,rinvkey) in resultat.resoucres[0].ressources_investissement"
              :key="'ri_'+rinvkey">
            <td >{{ investissement.libelle }}</td>
            <template v-if="investissement.montant[0].length > 0">
              <td class="text-right"
                  v-for="(montant, mtkey) in investissement.montant[0]"
                  :key="'aumontant'+mtkey">{{ montant.toLocaleString() }}</td>
            </template>
            <template v-else>
              <td class="text-center"
                  :colspan="resultat.periode[0].length">Aucune données...</td>
            </template>
          </tr>
        </tbody>
        
        <thead v-if="contenu.r">
          <tr @click="showContent('ra')">
            <th class="tht-blue text-uppercase"> Autres ressources </th>
            <!-- <th class="tht-blue text-uppercase text-right">{{ total.r_autre.toLocaleString() }}</th> -->
            <th class="tht-blue text-right"
                v-for="(total, totkey) in total.r_autre"
                :key="'autotal'+totkey">{{ total.toLocaleString() }}</th>
          </tr>
        </thead>
        
        <tbody v-if="contenu.r && contenu.ra">
          <tr scope="row"
              v-for="(autre,raukey) in resultat.resoucres[0].autres_ressources"
              :key="'ra_'+raukey">
            <td >{{ autre.libelle }}</td>
            <template v-if="autre">
              <td class="text-right"
                  v-for="(montant, mtkey) in autre.montant[0]"
                  :key="'aumontant'+mtkey">{{ montant.toLocaleString() }}</td>
            </template>
            <template v-else>
              <td class="text-center"
                  :colspan="resultat.periode[0].length">Aucune données...</td>
            </template>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>
<style>
  .table tbody td,
  .table tfoot td,
  .table thead th,
  .form input.form-control,
  .form button.btn-info{
    font-size: 0.54em;
  }
  .table tbody tr{
    cursor: pointer;
  }
  .card_dark {
    background: #016677;
    color:#fff;
  }
  .s_card:not(.card_dark) {
      background: #0097A9;
      color: #fff;
  }
  .s_card {
      display: flex;
      justify-content: space-between;
      padding: 20px;
  }
  .s_cont h3 {
      font-size: 50px;
      margin: 0;
      font-weight: 400;
  }
  .s_cont p {
      font-size: 12px;
      margin: 0;
      font-weight: 500;
  }
  .info_row_2.row {
      margin-top: 80px;
  }

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
export default {
  name: "RecapBusiness",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    periode:"01-01 au 31-12 " + new Date().getFullYear(),
    showing:"levee",
    resultat:"",
    loaded:false,
    long:0,
    modification:false,
    contenu:{
      c : true,
      r : true,
      ri : true,
      re : true,
      ra : true,
      ci : true,
      ce : true,
      ca : true,
    },
    total:{
      charge:[],
      ressource:[],
      r_investissement:[],
      r_exploitation:[],
      r_autre:[],
      c_investissement:[],
      c_exploitation:[],
      c_autre:[],
    }
  }),
  created() {
    this.recapBusiness({id:this.$route.params.id,date_debut:this.annee_debut,date_fin:this.annee_fin})
  },
  watch: {
    business(){
      if (this.business) {
        console.log(this.business)
        this.resultat = this.business
        this.total={
          charge:[],
          ressource:[],
          r_investissement:[],
          r_exploitation:[],
          r_autre:[],
          c_investissement:[],
          c_exploitation:[],
          c_autre:[],
        }
        for (let index = 0; index < this.resultat.periode[0].length; index++) {
          this.total.charge.push(0)
          this.total.c_exploitation.push(0)
          this.total.c_investissement.push(0)
          this.total.c_autre.push(0)

          this.total.ressource.push(0)
          this.total.r_exploitation.push(0)
          this.total.r_investissement.push(0)
          this.total.r_autre.push(0)
          
        }
        // totaux pour les charges
        this.resultat.charges[0].charges_exploitation.forEach(c_exploitation => {
          for (let index = 0; index < c_exploitation.montant[0].length; index++) {
            this.total.c_exploitation[index]+=Number(c_exploitation.montant[0][index])
          }
        })
        this.resultat.charges[0].charges_investissement.forEach(c_investissement => {
          for (let index = 0; index < c_investissement.montant[0].length; index++) {
            this.total.c_investissement[index]+=Number(c_investissement.montant[0][index])
          }
        })
        this.resultat.charges[0].autres_charges.forEach(c_autre => {
          for (let index = 0; index < c_autre.montant[0].length; index++) {
            this.total.c_autre[index]+=Number(c_autre.montant[0][index])
          }
        })
        for (let index = 0; index < this.total.c_exploitation.length; index++) {
          this.total.charge[index]=this.total.c_exploitation[index]+this.total.c_investissement[index]+this.total.c_autre[index]
        }
        // totaux pour les ressources
        this.resultat.resoucres[0].ressources_exploitation.forEach(r_exploitation => {
          for (let index = 0; index < r_exploitation.montant[0].length; index++) {
            this.total.r_exploitation[index]+=Number(r_exploitation.montant[0][index])
          }
        })
        this.resultat.resoucres[0].ressources_investissement.forEach(r_investissement => {
          for (let index = 0; index < r_investissement.montant[0].length; index++) {
            this.total.r_investissement[index]+=Number(r_investissement.montant[0][index])
          }
        })
        this.resultat.resoucres[0].autres_ressources.forEach(r_autre => {
          for (let index = 0; index < r_autre.montant[0].length; index++) {
            this.total.r_autre[index]+=Number(r_autre.montant[0][index])
          }
        })
        for (let index = 0; index < this.total.r_exploitation.length; index++) {
          this.total.ressource[index]=this.total.r_exploitation[index]+this.total.r_investissement[index]+this.total.r_autre[index]
        }
        console.log(this.total)

        this.loaded=true
      }
    },
    failBusiness(){
      if (this.failBusiness) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    
  },
  computed: {
    ...mapGetters(["business","failBusiness",])
  },
  methods: {
    ...mapActions(["recapBusiness",]),
    ...mapMutations(["setBusiness","setFailBusiness"]),
    
    changeYear() {
      this.recapBusiness({id:this.$route.params.id,date_debut:this.annee_debut,date_fin:this.annee_fin})

      var split_annee_debut=this.annee_debut.split("-")
      var split_annee_fin=this.annee_fin.split("-")
      if (split_annee_debut[0] == split_annee_fin[0]) {
        this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
      }else{
        this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+"-"+split_annee_debut[0]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
      }
    },
    modifierProjet(){
      if (this.form.commentaire==null) {
        this.form.commentaire=""
      }
      this.putProjet(this.form.data)
    },
    showContent(key){
      if (this.contenu[key]) {
        this.contenu[key]=false
      }else{
        this.contenu[key]=true
      }
    }
  }
}
</script>
